
import { mapState } from "vuex";
import { library } from "@fortawesome/fontawesome-svg-core";
import VPagination from "@/components/VPagination.vue";
import { required } from "@vuelidate/validators";
import _ from "lodash";
import {
  GET_FOLDERS,
  GET_BULK_RULES,
  GET_BULK_RULE,
  CREATE_BULK_RULE,
  UPDATE_BULK_RULE,
  RESET_BULK_RULE,
  DELETE_BULK_RULE
} from "@/store/actions.type";

import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { defineComponent, State } from "@vue/runtime-core";
import useVuelidate from "@vuelidate/core";

library.add(faTimes);

export default defineComponent({
  name: "BulkRules",
  setup() {
    return { v$: useVuelidate() };
  },
  components: {
    VPagination
  },
  props: {
    itemsPerPage: {
      type: Number,
      required: false,
      default: 10
    }
  },
  data() {
    return {
      currentPage: 1,
      searchText: "",
      loading: false,
      error: "" as any,
      selectedRule: {
        id: "",
        name: "",
        field: "",
        value: "",
        rows: new Array<any>(),
        destinationFolderId: "",
        originFolderId: ""
      },
      newRow: {
        field: "",
        pattern: "",
        value: ""
      },
      showFaultyFields: false
    };
  },
  async mounted() {
    this.error = "";
    this.loading = true;

    await this.$store.dispatch(GET_FOLDERS, {
      paginationInformation: { currentPage: 1, itemsPerPage: 9999999 }
    });
    await this.$store.dispatch(GET_BULK_RULES, this.listConfig);

    try {
      if (this.$route.params.ruleId) {
        this.getBulkRule(this.$route.params.ruleId);
      } else if (
        typeof this.bulkRule !== "undefined" &&
        this.bulkRule != null
      ) {
        this.$store.dispatch(RESET_BULK_RULE);
      }
      this.error = "";
    } catch (e) {
      this.error = e;
    } finally {
      this.loading = false;
    }
  },
  computed: {
    ...mapState({
      folders: (state) => (state as State).folders.folders,
      bulkRules: (state) => (state as State).bulkRules.bulkRules,
      bulkRule: (state) => (state as State).bulkRules.bulkRule,
      rulesPagination: (state) => (state as State).bulkRules.bulkRulesPagination
    }),
    listConfig() {
      const filters = {
        paginationInformation: {
          currentPage: this.currentPage,
          itemsPerPage: this.itemsPerPage
        },
        selectedRuleId: null as any
      };

      if (this.$route.params.ruleId) {
        filters.selectedRuleId = this.$route.params.ruleId;
      }

      return filters;
    },
  },
  validations() {
    return {
      selectedRule: {
        name: { required },
        originFolderId: { required },
        destinationFolderId: { required }
      },
      newRow: {
        field: { required },
        value: { required }
      }
    };
  },
  watch: {
    currentPage() {
      this.getBulkRules();
    }
  },
  methods: {
    getFolders() {
      this.$store.dispatch(GET_FOLDERS, {
        paginationInformation: { currentPage: 1, itemsPerPage: 9999999 }
      });
    },
    validateFolders: function () {
      if (
        this.selectedRule.destinationFolderId !== "" &&
        this.selectedRule.originFolderId !== "" &&
        this.selectedRule.destinationFolderId ===
          this.selectedRule.originFolderId
      ) {
        return false;
      }
      return true;
    },
    validateRule: function () {
      if (this.selectedRule.name.length === 0) {
        return false;
      }
      if (this.selectedRule.rows.some(x => x.value === "")) {
        return false;
      }
      if (this.selectedRule.destinationFolderId === "") {
        return false;
      }
      if (this.selectedRule.originFolderId === "") {
        return false;
      }
      if (this.selectedRule.rows.length < 1) {
        return false;
      }
      return this.validateFolders();
    },
    validateRow: function () {
      if (this.newRow.value.length === 0) {
        return false;
      }
      if (this.newRow.value === "") {
        return false;
      }
      if (this.newRow.field === "") {
        return false;
      }
      return true;
    },
    getBulkRules() {
      _.debounce(() => {
        this.$store.dispatch(RESET_BULK_RULE);
        this.$store.dispatch(GET_BULK_RULES, this.listConfig);
      }, 500)();
    },
    getBulkRule(id, isToggle = false) {
      var rl = this.bulkRule || { id: "" };
      if (isToggle && (id === "" || rl.id === id)) {
        this.$store.dispatch(RESET_BULK_RULE);
      } else {
        this.$store.dispatch(GET_BULK_RULE, { ruleId: id });
      }
    },
    deleteRow(data) {
      this.selectedRule.rows = this.selectedRule.rows.filter(function (el) {
        return el.id != data;
      });
    },
    async addRow() {
      if (!this.validateRow()) {
        this.showFaultyFields = true;
        return;
      }
      this.showFaultyFields = false;
      this.selectedRule.rows.push({ ...this.newRow });
      this.newRow = {
        field: "",
        pattern: "",
        value: ""
      };
    },
    async addBulkRule(executeRule) {
      if (!this.validateRule()) {
        this.showFaultyFields = true;
        return;
      }

      this.showFaultyFields = false;
      this.$store
        .dispatch(CREATE_BULK_RULE, {
          selectedRule: this.selectedRule,
          executeRule
        })
        .then(() => {
          this.resetPagination();
          this.getBulkRules();
          this.$store.dispatch(RESET_BULK_RULE);
        });
    },
    async updateBulkRule(executeRule) {
      this.$store
        .dispatch(UPDATE_BULK_RULE, {
          selectedRule: this.selectedRule,
          executeRule
        })
        .then(() => {
          this.getBulkRules();
          this.getBulkRule(this.bulkRule.id);
        });
    },
    async deleteBulkRule() {
      this.$store.dispatch(DELETE_BULK_RULE, this.selectedRule).then(() => {
        this.getBulkRules();
        this.unselectRule();
      });
    },
    unselectRule() {
      this.selectedRule = {
        id: "",
        name: "",
        field: "",
        value: "",
        rows: [],
        destinationFolderId: "",
        originFolderId: ""
      };
    },
    resetPagination() {
      this.currentPage = 1;
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch(RESET_BULK_RULE);

    next();
  }
});
