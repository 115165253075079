
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faLock,
  faLockOpen,
  faSpinner,
  faTimes
} from "@fortawesome/free-solid-svg-icons";
import AutoComplete from "primevue/autocomplete";
import moment from "moment";
import {
  AutoReplyGetResult,
  autoReplyActions
} from "@/store/auto-replies.module";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { defineComponent, State } from "@vue/runtime-core";
import List from "@/components/common/ListView.vue";
import RenderHtml from "@/components/RenderHtml.vue";
import { CkEditor } from "@/common/contracts/ckeditor";
import SortIcon from "@/components/common/SortIcon.vue";

library.add(faSpinner, faLock, faLockOpen, faTimes);

export default defineComponent({
  name: "AutoRepliesComponent",
  components: {
    List,
    RenderHtml,
    AutoComplete,
    SortIcon
  },
  data() {
    return {
      autoReplyActions: autoReplyActions,
      editor: CkEditor.CustomClassicEditor,
      domains: [] as any[],
      name: "Auto-Reply"
    };
  },
  methods: {
    resolveState: (state: State) => state.autoReplies,
    cast: (items: any) => items as AutoReplyGetResult[],
    searchDomains(search: string) {
      this.domains = [{ name: search }];
    },
    formatDate(date: Date | string) {
      return moment(date, "DD/MM/YYYY h:mm:ss");
    }
  }
});
