
import { library } from "@fortawesome/fontawesome-svg-core";
import { faFolder } from "@fortawesome/free-regular-svg-icons";

library.add(faFolder);

import MainNavigation from "@/components/MainNavigation.vue";
import Folders from "@/components/settings/Folders.vue";
import Rules from "@/components/settings/Rules.vue";
import Users from "@/components/settings/Users.vue";
import BulkRules from "@/components/settings/BulkRules.vue";
import BlockedSenders from "@/components/settings/BlockedSenders.vue";
import Domains from "@/components/settings/Domains.vue";
import AutoReply from "@/components/settings/AutoReplies.vue";
import EmailTemplates from "@/components/settings/EmailTemplates.vue";
import ErrorLog from "@/components/settings/ErrorLog.vue";
import Companies from "@/components/settings/Companies.vue";
import DomainGreenlist from "@/components/settings/DomainGreenlist.vue";
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
  name: "SettingsComponent",
  props: {},
  components: {
    MainNavigation,
    Folders,
    Rules,
    Users,
    BulkRules,
    BlockedSenders,
    Domains,
    AutoReply,
    EmailTemplates,
    Companies,
    ErrorLog,
    DomainGreenlist
  }
});
