
import { defineComponent } from "@vue/runtime-core";
import {
  GET_PENDING_DOMAINS,
  GET_INCOMPLETE_DOMAINS
} from "../store/actions.type";

export default defineComponent({
  name: "FileDownload",
  data() {
    return {
      Csv: [],
    };
  },
  methods: {
    downloadPending() {
      this.$store.dispatch(GET_PENDING_DOMAINS);
    },
    downloadIncomplete() {
      this.$store.dispatch(GET_INCOMPLETE_DOMAINS);
    }
  }
});
