
import { mapState } from "vuex";
import VPagination from "@/components/VPagination.vue";
import {
  GET_FOLDERS,
  GET_RULES,
  CREATE_RULE,
  UPDATE_RULE,
  RESET_RULE,
  DELETE_RULE,
  GET_RULE
} from "@/store/actions.type";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { defineComponent, State } from "@vue/runtime-core";

library.add(faTimes);

export default defineComponent({
  name: "RulesComponent",
  components: {
    VPagination
  },
  props: {
    itemsPerPage: {
      type: Number,
      required: false,
      default: 10
    }
  },
  data() {
    return {
      rule: null as any,
      currentPage: 1,
      searchText: "",
      loading: false,
      error: "" as any,
      selectedRule: {
        id: "",
        name: "",
        field: "",
        value: "",
        destinationFolderId: ""
      }
    };
  },
  async mounted() {
    this.error = "";
    this.loading = true;

    try {
      this.$store.dispatch(GET_FOLDERS, {
        paginationInformation: { currentPage: 1, itemsPerPage: 9999999 }
      });
      this.$store.dispatch(GET_RULES, this.listConfig);
      this.error = "";
    } catch (e) {
      this.error = e;
    } finally {
      this.loading = false;
    }
  },
  computed: {
    ...mapState({
      folders: (state) => (state as State).folders.folders,
      rules: (state) => (state as State).rules.rules,
      rulesPagination: (state) => (state as State).rules.rulesPagination
    }),
    listConfig() {
      const filters = {
        paginationInformation: {
          currentPage: this.currentPage,
          itemsPerPage: this.itemsPerPage
        },
        selectedRuleId: null as any
      };

      if (this.$route.params.ruleId) {
        filters.selectedRuleId = this.$route.params.ruleId;
      }

      return filters;
    },
  },
  watch: {
    currentPage() {
      this.getRules();
    }
  },
  methods: {
    getFolders() {
      this.$store.dispatch(GET_FOLDERS, {
        paginationInformation: { currentPage: 1, itemsPerPage: 9999999 }
      });
    },
    async getRules() {
      await this.$store.dispatch(RESET_RULE);
      await this.$store.dispatch(GET_RULES, this.listConfig);
    },
    getRule(id, isToggle = false) {
      var rl = this.rule || { id: "" };

      if (isToggle && (id === "" || rl.id === id)) {
        this.$store.dispatch(RESET_RULE);
      } else {
        this.$store.dispatch(GET_RULE, { ruleId: id });
      }
    },
    validateRule: function () {
      if (this.selectedRule.name.length === 0) {
        return false;
      }
      if (this.selectedRule.field === "") {
        return false;
      }
      if (this.selectedRule.value === "") {
        return false;
      }
      if (this.selectedRule.destinationFolderId === "") {
        return false;
      }
      return true;
    },
    unselectRule() {
      this.selectedRule = {
        id: "",
        name: "",
        field: "",
        value: "",
        destinationFolderId: ""
      };
    },
    async addRule() {
      this.$store.dispatch(CREATE_RULE, this.selectedRule).then(() => {
        this.resetPagination();
        this.getRules();
        this.$store.dispatch(RESET_RULE);
      });
    },
    async updateRule() {
      this.$store.dispatch(UPDATE_RULE, this.selectedRule).then(() => {
        this.getRules();
        this.unselectRule();
      });
    },
    deleteRule() {
      this.$store.dispatch(DELETE_RULE, this.selectedRule.id).then(() => {
        this.getRules();
        this.unselectRule();
      });
    },
    resetPagination() {
      this.currentPage = 1;
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch(RESET_RULE);

    next();
  }
});
