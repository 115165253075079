
import { defineComponent, State } from "vue";
import { mapState } from "vuex";
import VPagination from "@/components/VPagination.vue";
import { UsersService } from "@/common/api.service";

import {
  GET_USERS,
  GET_USER,
  ADD_USER,
  UPDATE_USER,
  DELETE_USER,
  SEND_RESET_PASSWORD,
  RESET_USER
} from "@/store/actions.type";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { toast } from "@/common/toast/toast.service";

library.add(faTrash, faTimes);

export default defineComponent({
  name: "CSUsers",
  components: {
    VPagination
  },
  props: {
    itemsPerPage: {
      type: Number,
      required: false,
      default: 10
    }
  },
  data() {
    return {
      resetPasswordLink: null,
      currentPage: 1,
      perPage: 30,
      searchText: "",
      loading: false,
      error: "" as any,
      selectedUser: {
        id: "",
        forename: "",
        surname: "",
        email: ""
      }
    };
  },
  async mounted() {
    this.error = "";
    this.loading = true;

    try {
      await this.$store.dispatch(GET_USERS, this.listConfig);

      if (this.$route.params.userId) {
        this.fetchUser(this.$route.params.userId);
      } else if (typeof this.user !== "undefined" && this.user != null) {
        this.$store.dispatch(RESET_USER);
      }

      this.error = "";
    } catch (e) {
      this.error = e;
    } finally {
      this.loading = false;
    }
  },
  computed: {
    ...mapState({
      users: (state) => (state as State).users.users,
      user: (state) => (state as State).users.user,
      usersPagination: (state) => (state as State).users.usersPagination
    }),
    listConfig() {
      const filters = {
        paginationInformation: {
          currentPage: this.currentPage,
          itemsPerPage: this.perPage
        },
        searchText: "",
        selectedUserId: null as any
      };

      if (this.searchText) {
        filters.searchText = this.searchText;
      }

      if (this.$route.params.userId) {
        filters.selectedUserId = this.$route.params.userId;
      }

      return filters;
    },
  },
  watch: {
    perPage() {
      this.fetchUsers();
    },
    currentPage() {
      this.fetchUsers();
    },
    searchText() {
      this.resetPagination();
      this.fetchUsers();
    }
  },
  methods: {
    async fetchUsers() {
      await this.$store.dispatch(RESET_USER);
      await this.$store.dispatch(GET_USERS, this.listConfig);
    },
    fetchUser(id, isToggle = false) {
      var usr = this.user || { id: "" };

      if (isToggle && (id === "" || usr.id === id)) {
        this.$store.dispatch(RESET_USER);
      } else {
        this.$store.dispatch(GET_USER, { id: id });
      }
    },
    validateUser: function () {
      if (this.selectedUser.forename.length === 0) {
        return false;
      }
      if (this.selectedUser.email.length === 0) {
        return false;
      }
      if (this.selectedUser.surname.length === 0) {
        return false;
      }
      return true;
    },
    async addUser() {
      this.$store.dispatch(ADD_USER, this.selectedUser).then(() => {
        this.resetPagination();
        this.fetchUsers();
        this.$store.dispatch(RESET_USER);
      });
    },
    async updateUser() {
      this.$store.dispatch(UPDATE_USER, this.selectedUser).then(() => {
        this.fetchUsers();
        this.fetchUser(this.user.id);
      });
    },
    async deleteUser(id) {
      this.$store.dispatch(DELETE_USER, id).then(() => {
        this.$store.dispatch(RESET_USER).then(() => {
          this.fetchUsers();
        });
      });
    },
    unselectUser() {
      this.selectedUser = {
        id: "",
        forename: "",
        surname: "",
        email: ""
      };
    },
    async sendResetPassword() {
      this.$store
        .dispatch(SEND_RESET_PASSWORD, { id: this.user.id })
        .then(() => {
          this.fetchUser(this.user.id);
        });
    },
    async generateResetPasswordLink() {
      let response = await UsersService.generateResetPasswordLink({
        id: this.user.id
      });

      if (typeof response === "undefined") return;

      if (response.data.succeeded === true) {
        this.resetPasswordLink = response.data.result;
      }
    },
    resetPagination() {
      this.currentPage = 1;
    },
    onCopy: function () {
      toast.success("Link copied!");
    },
    onError: function () {
      toast.error("Unable to copy the link, please try again!");
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch(RESET_USER);

    next();
  }
});
