
import { toast } from "@/common/toast/toast.service";
import ListView from "@/components/common/ListView.vue";
import {
  companiesActions,
  CompanyGetResult,
  CompanyGetPagedRequest,
  EmailTemplateKind,
  CompaniesService
} from "@/store/companies.module";
import {
  EmailTemplateGetResult,
  EmailTemplatesService
} from "@/store/email-templates.module";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faClipboard, faCopy } from "@fortawesome/free-regular-svg-icons";
import { defineComponent, State } from "@vue/runtime-core";
import clipboard from "clipboardy/browser";
import moment from "moment";
import { ref } from "vue";
import ConfirmDialog, {
  IConfirmDialog
} from "@/components/common/ConfirmView.vue";
import SortIcon from "@/components/common/SortIcon.vue";

library.add(faClipboard, faCopy);

export default defineComponent({
  name: "EmailTemplatesComponent",
  components: {
    ListView,
    ConfirmDialog,
    SortIcon
  },
  setup() {
    const confirmDialog = ref<IConfirmDialog>(null as any);
    return {
      confirmDialog
    };
  },
  data() {
    return {
      companiesService: new CompaniesService(),
      search: "" as string,
      companiesActions: companiesActions,
      name: "Company",
      welcomeEmailOptions: Array<EmailTemplateGetResult>()
    };
  },
  async beforeMount() {
    this.welcomeEmailOptions = (
      await new EmailTemplatesService().getPaged({
        kind: EmailTemplateKind.WelcomeEmail,
        paginationInformation: {
          currentPage: 0,
          itemsPerPage: 2000
        }
      })
    ).data.result;
    this.welcomeEmailOptions = [
      { id: undefined, name: "None selected" },
      ...this.welcomeEmailOptions
    ];
  },
  methods: {
    getWelcomeEmailOptions: (
      company: CompanyGetResult,
      options: Array<EmailTemplateGetResult>
    ) => {
      return options.filter(
        (option) => !!option.id || !company.welcomeEmailTemplateId
      );
    },
    setTemplate: async function (
      company: CompanyGetResult,
      templateId: string | undefined
    ) {
      const ok = await this.confirmDialog.show({
        title: "Change Template",
        message: `Are you sure you want to change the template for "${company.name}"?`,
        okButton: "Change"
      });
      if (ok) {
        const success = await this.companiesService.setEmailTemplate(
          company.id as string,
          templateId as string
        );
        if (success) {
          company.welcomeEmailTemplateId = templateId;
          company.welcomeEmailTemplateIdPrevious = templateId;
        } else {
          company.welcomeEmailTemplateId =
            company.welcomeEmailTemplateIdPrevious;
        }
      } else {
        company.welcomeEmailTemplateId = company.welcomeEmailTemplateIdPrevious;
      }
    },
    filter: function (v: CompanyGetPagedRequest) {
      v.search = this.search;
    },
    setClipboard: (value?: string) => {
      if (value) {
        clipboard.write(value);
        toast.info(`Copied '${value}' to clipboard`);
      }
    },
    formatAddress: (item: CompanyGetResult) => {
      return [
        item.addressLine1,
        item.addressLine2,
        item.city,
        item.postCode,
        item.country
      ]
        .map((_) => (_ ?? "").trim())
        .filter((_) => !!_)
        .join("\n");
    },
    resolveState: (state: State) => state.companies,
    cast: (items: any) => items as CompanyGetResult[],
    formatDate(date: Date | string) {
      return moment(date, "DD/MM/YYYY h:mm:ss");
    }
  }
});
