
import { mapState } from "vuex";
import VPagination from "@/components/VPagination.vue";
import FileUpload from "@/components/FileUpload.vue";
import FileDownload from "@/components/FileDownload.vue";
import {
  GET_DOMAINS,
  UPDATE_DOMAIN,
  RESET_DOMAIN,
  DELETE_DOMAIN,
  GET_DOMAIN_COUNTS
} from "@/store/actions.type";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { defineComponent, State } from "@vue/runtime-core";

import { PaginatedQuery } from "@/common/contracts/paginated.result";

library.add(faTrash);

export default defineComponent({
  name: "DomainsRules",
  components: {
    VPagination,
    FileUpload,
    FileDownload
  },
  props: {
    itemsPerPage: {
      type: Number,
      required: false,
      default: 10
    }
  },
  data() {
    return {
      currentPage: 1,
      loading: false,
      error: "" as any,
      interval: null as any,
      selectedDomain: {
        id: "",
        name: "",
        error: "",
        errorType: ""
      },
      counts: {
        awaitingConfiguration: 0,
        awaitingVerification: 0,
        completedDomains: 0
      }
    };
  },
  async mounted() {
    this.error = "";
    this.loading = true;

    await this.getDomainCounts();

    await this.$store
      .dispatch(GET_DOMAINS, {
        paginationInformation: { currentPage: 1, itemsPerPage: 10 }
      } as PaginatedQuery)
      .catch((e) => {
        this.error = e;
      });

    this.loading = false;
    //   // TODO: JC: Destroy on unmount
    this.interval = setInterval(() => this.getDomainCounts(), 30000);
  },
  unmounted() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  },
  computed: {
    ...mapState({
      domains: (state) => (state as State).domains.domains,
      domain: (state) => (state as State).domains.domain,
      domainsPagination: (state) => (state as State).domains.domainsPagination
    }),
    listConfig(): PaginatedQuery {
      const filters: PaginatedQuery = {
        paginationInformation: {
          currentPage: this.currentPage,
          itemsPerPage: this.itemsPerPage
        }
      };

      return filters;
    }
  },
  watch: {
    currentPage() {
      this.getDomains();
    }
  },
  methods: {
    getDomains() {
      // _.debounce(() => {
      //   // this.$store.dispatch(RESET_DOMAINS);
      // }, 500)();
      this.$store.dispatch(GET_DOMAINS, this.listConfig);
    },
    deleteDomain(id) {
      this.$store.dispatch(DELETE_DOMAIN, id).then(() => {
        this.getDomains();
      });
    },
    updateDomain(id) {
      this.$store.dispatch(UPDATE_DOMAIN, id).then(() => {
        this.getDomains();
      });
    },
    resetPagination() {
      this.currentPage = 1;
    },
    async getDomainCounts() {
      this.counts = await this.$store.dispatch(GET_DOMAIN_COUNTS);
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch(RESET_DOMAIN);

    next();
  }
});
