
import { defineComponent } from "vue-demi";
import {
  faSort,
  faSortDown,
  faSortUp
} from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";

library.add(faSort);
library.add(faSortUp);
library.add(faSortDown);

export default defineComponent({
  name: "SortIcon",
  props: {
    field: {
      type: String,
      required: true,
      default: null
    },
    sortBy: {
      type: String,
      required: true,
      default: null
    },
    sortByDescending: {
      type: String,
      required: true,
      default: null
    }
  }
});
