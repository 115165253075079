
import { RegisterDomain } from "@/store/domains.module";
import { defineComponent } from "@vue/runtime-core";
import { UPLOAD_DOMAINS, REGISTER_DOMAIN } from "../store/actions.type";

declare var $: any;

export default defineComponent({
  name: "FileUpload",
  components: {},
  data() {
    return {
      Csv: {},
      registerDomainName: ""
    };
  },
  methods: {
    submitFile() {
      this.$store
        .dispatch(UPLOAD_DOMAINS, (this.$refs.file as any).files[0])
        .then(() => {
          $("#file").val("");
          this.$emit("getUpdatedCount");
        });
    },
    registerDomain() {
      this.$store
        .dispatch(REGISTER_DOMAIN, {
          name: this.registerDomainName
        } as RegisterDomain)
        .then(() => {
          this.registerDomainName = "";
        });
    }
  }
});
