import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "cursor-pointer ml-2" }
const _hoisted_2 = { title: "Clear Button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    _createElementVNode("a", _hoisted_2, [
      (_ctx.sortBy !== _ctx.field)
        ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
            key: 0,
            class: "text-secondary",
            icon: ['fas', 'sort']
          }))
        : _createCommentVNode("", true),
      (_ctx.sortBy === _ctx.field && _ctx.sortByDescending)
        ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
            key: 1,
            class: "text-secondary",
            icon: ['fas', 'sort-down']
          }))
        : _createCommentVNode("", true),
      (_ctx.sortBy === _ctx.field && !_ctx.sortByDescending)
        ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
            key: 2,
            class: "text-secondary",
            icon: ['fas', 'sort-up']
          }))
        : _createCommentVNode("", true)
    ])
  ]))
}