
import ListView from "@/components/common/ListView.vue";
import { defineComponent } from "@vue/runtime-core";
import {
  errorLogActions,
  ErrorLogGetResult,
  ErrorLogService
} from "@/store/error-log.module";
import { ref, State } from "vue";
import { EnumService } from "@/common/enum.service";
import ConfirmDialog, {
  IConfirmDialog
} from "@/components/common/ConfirmView.vue";
import moment from "moment";
import { DateService } from "@/common/date.service";
import SortIcon from "@/components/common/SortIcon.vue";

export default defineComponent({
  name: "ErrorLogComponent",
  components: {
    ListView,
    ConfirmDialog,
    SortIcon
  },
  setup() {
    const confirmDialog = ref<IConfirmDialog>(null as any);
    return {
      confirmDialog
    };
  },
  data() {
    return {
      dateService: new DateService(),
      enumService: new EnumService(),
      name: "Error Log",
      errorLogActions: errorLogActions,
      resolveState: (state: State) => state.errorLog
    };
  },
  methods: {
    async retry(item: ErrorLogGetResult, reload: () => any) {
      const ok = await this.confirmDialog.show({
        title: "Retry",
        message: `Are you sure you want to retry this email?`,
        okButton: "Retry"
      });
      if (ok) {
        await new ErrorLogService().retry(item.id);
        reload();
      }
    },
    formatDate(date: Date | string | null | undefined) {
      if ((date ?? "") == "") {
        return "";
      }
      return moment(new Date(date as any)).format("DD/MM/YYYY");
    },
    cast: (items: any) => items as ErrorLogGetResult[]
  }
});
