
import { toast } from "@/common/toast/toast.service";
import ListView from "@/components/common/ListView.vue";
import {
  domainGreenlistActions,
  DomainGreenlistGetResult
} from "@/store/domain-greenlist.module";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faClipboard, faCopy } from "@fortawesome/free-regular-svg-icons";
import { defineComponent, State } from "@vue/runtime-core";
import clipboard from "clipboardy/browser";
import moment from "moment";
import SortIcon from "@/components/common/SortIcon.vue";

library.add(faClipboard, faCopy);

export default defineComponent({
  name: "DomainComponent",
  components: {
    ListView,
    SortIcon
  },
  data() {
    return {
      domainGreenlistActions: domainGreenlistActions,
      name: "Good Domains"
    };
  },
  methods: {
    setClipboard: (value?: string) => {
      if (value) {
        clipboard.write(value);
        toast.info(`Copied '${value}' to clipboard`);
      }
    },
    resolveState: (state: State) => state.domainGreenlist,
    cast: (items: any) => items as DomainGreenlistGetResult[],
    formatDate(date: Date | string) {
      return moment(date, "DD/MM/YYYY h:mm:ss");
    }
  }
});
