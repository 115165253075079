
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faLock,
  faLockOpen,
  faSpinner,
  faTimes
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import {
  emailTemplatesActions,
  EmailTemplateGetResult,
  EmailTemplateKind
} from "@/store/email-templates.module";
import { defineComponent, State } from "@vue/runtime-core";
import ListView from "@/components/common/ListView.vue";
import RenderHtml from "@/components/RenderHtml.vue";
import { CkEditor, CustomEditorConfig } from "@/common/contracts/ckeditor";
import { EmailTemplatesService } from "@/store/email-templates.module";
import AutoComplete from "primevue/autocomplete";
import { Enum, EnumEntry } from "@/common/contracts/enum-parser";
import type ClassicEditorType from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import SortIcon from "@/components/common/SortIcon.vue";

library.add(faSpinner, faLock, faLockOpen, faTimes);

export default defineComponent({
  name: "EmailTemplatesComponent",
  components: {
    ListView,
    RenderHtml,
    AutoComplete,
    SortIcon
  },
  async beforeMount() {
    this.emailTemplateKindsSource = Object.freeze(Enum.Entries(EmailTemplateKind).entries) as EnumEntry<EmailTemplateKind>[];
    this.kind = this.emailTemplateKindsSource.filter(
      (_) => _.value === EmailTemplateKind.WelcomeEmail
    )[0];
    this.emailTemplateKinds = [...this.emailTemplateKindsSource];
    await this.updateVariables();
  },
  data() {
    return {
      editorConfig: null as CustomEditorConfig | null,
      editorType: CkEditor.CustomClassicEditor,
      name: "Email Template",
      emailTemplatesActions: emailTemplatesActions,
      emailTemplateKindsSource: (null as any) as EnumEntry<EmailTemplateKind>[],
      emailTemplateKinds: (null as any) as EnumEntry<EmailTemplateKind>[],
      kind: (null as any) as EnumEntry<EmailTemplateKind>
    };
  },
  methods: {
    editorReady(editor: ClassicEditorType) {
      const item = editor.ui.view.toolbar.items.get(editor.ui.view.toolbar.items.length - 1);
      if(item) {
        (item as any).buttonView.label = editor.t("Variables");
      }
    },
    cleanSelected(item: EmailTemplateGetResult) {
      item.body ??= "";
      return true;
    },
    resolveKind(kind: EmailTemplateKind): EnumEntry<EmailTemplateKind> {
      return this.emailTemplateKindsSource.find(_ => _.value === kind) ??
        this.emailTemplateKindsSource.filter(_ => _.value === EmailTemplateKind.WelcomeEmail)[0];
    },
    createEmptyItem(): EmailTemplateGetResult {
      return {
        kind: EmailTemplateKind.WelcomeEmail,
        isAssignable: true,
        body: ""
      };
    },
    async kindSelected(selectedItem: EmailTemplateGetResult, kind: EnumEntry<EmailTemplateKind>) {
      selectedItem.kind = kind.value;
      await this.updateVariables(kind);
    },
    searchKinds(search: string) {
      this.emailTemplateKinds = this.emailTemplateKindsSource.filter(
        (_) => _.friendlyName.toLowerCase().indexOf(search.toLowerCase()) !== -1
      );
    },
    resolveKindName(kind: EmailTemplateKind) {
      return this.resolveKind(kind)?.friendlyName ?? "Unknown";
    },
    updateVariables: async function (kind?: EnumEntry<EmailTemplateKind>) {
      kind ??= this.kind;
      const variables = await new EmailTemplatesService().getVariables(
        kind.value as EmailTemplateKind
      );
      this.editorConfig = CkEditor.newCustomConfig((_) =>
        CkEditor.addPlaceholderToolbarItem(_, ...variables.data)
      );
      this.kind = kind;
    },
    resolveState: (state: State) => state.emailTemplates,
    cast: (items: any) => items as EmailTemplateGetResult[],
    formatDate(date: Date | string) {
      return moment(date, "DD/MM/YYYY h:mm:ss");
    }
  }
});
