
import { mapState } from "vuex";
import VPagination from "@/components/VPagination.vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import ConfirmDialog, { IConfirmDialog } from "@/components/common/ConfirmView.vue";
import {
  faSpinner,
  faLock,
  faLockOpen,
  faTimes
} from "@fortawesome/free-solid-svg-icons";

library.add(faSpinner, faLock, faLockOpen, faTimes);

import {
  GET_FOLDERS,
  GET_FOLDER,
  CREATE_FOLDER,
  RENAME_FOLDER,
  RESET_FOLDER,
  DELETE_FOLDER
} from "@/store/actions.type";
import { defineComponent, State } from "@vue/runtime-core";
import moment from "moment";
import { ref } from "vue";

export default defineComponent({
  name: "FoldersComponent",
  setup() {
    const confirmDialog = ref<IConfirmDialog>(null as any);

    return {
      confirmDialog
    };
  },
  components: {
    VPagination,
    ConfirmDialog
  },
  props: {
    itemsPerPage: {
      type: Number,
      required: false,
      default: 10
    }
  },
  data() {
    return {
      currentPage: 1,
      loading: false,
      folder: null as any,
      error: "" as any,
      selectedFolder: {
        id: "",
        name: "",
        protected: false,
        unreadCount: 0
      }
    };
  },
  async mounted() {
    this.error = "";
    this.loading = true;

    try {
      this.$store.dispatch(GET_FOLDERS, this.listConfig);
      this.error = "";
    } catch (e) {
      this.error = e;
    } finally {
      this.loading = false;
    }
  },
  computed: {
    ...mapState({
      folders: (state) => (state as State).folders.folders,
      foldersPagination: (state) => (state as State).folders.foldersPagination
    }),
    listConfig() {
      const filters = {
        paginationInformation: {
          currentPage: this.currentPage,
          itemsPerPage: this.itemsPerPage
        }
      };
      return filters;
    }
  },
  methods: {
    formatDate(date: Date | string) {
      return moment(date).format("DD/MM/yyyy H:mm:SS");
    },
    async getFolders() {
      await this.$store.dispatch(RESET_FOLDER);
      await this.$store.dispatch(GET_FOLDERS, this.listConfig);


    },
    getFolder(id, isToggle = false) {
      var fld = this.folder || { id: "" };

      if (isToggle && (id === "" || fld.id === id)) {
        this.$store.dispatch(RESET_FOLDER);
      } else {
        this.$store.dispatch(GET_FOLDER, { folderId: id });
      }
    },
    async addFolder() {
      this.$store.dispatch(CREATE_FOLDER, this.selectedFolder).then(() => {
        this.resetPagination();
        this.getFolders();
        this.$store.dispatch(RESET_FOLDER);
      });
    },
    async renameFolder() {
      this.$store.dispatch(RENAME_FOLDER, this.selectedFolder).then(() => {
        this.getFolders();
      });
    },
    unselectFolder() {
      this.selectedFolder = {
        id: "",
        name: "",
        protected: false,
        unreadCount: 0
      };
    },
    async deleteFolder() {
      let message = "Are you sure you want to delete this folder?";

      if (this.selectedFolder.unreadCount > 0) {
        message = `${message} This will also delete ${this.selectedFolder.unreadCount} unread message${(this.selectedFolder.unreadCount === 1 ? "" : "s")}.`
      };

      const ok = await this.confirmDialog.show({
        title: "Delete",
        message: message,
        okButton: "Delete"
      });
      if (ok) {
        this.$store.dispatch(DELETE_FOLDER, this.selectedFolder).then(() => {
          this.getFolders();
        });
      }
    },
    resetPagination() {
      this.currentPage = 1;
    }
  },
  watch: {
    currentPage() {
      this.getFolders();
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch(RESET_FOLDER);

    next();
  }
});
