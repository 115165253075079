
import { mapState } from "vuex";
import VPagination from "@/components/VPagination.vue";
import {
  GET_FOLDERS,
  GET_RULES,
  GET_RULE,
  RESET_RULE,
  DELETE_RULE,
  BLOCK_SENDER,
} from "@/store/actions.type";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { defineComponent, State } from "@vue/runtime-core";
import {BlockedSenderModel} from "@/store/rules.module";
import {ref} from "vue";
import ConfirmDialog, { IConfirmDialog } from "@/components/common/ConfirmView.vue";

library.add(faTrash);

export default defineComponent({
  name: "BlockedSendersRules",
  setup() {
    const confirmDialog = ref<IConfirmDialog>(null as any);

    return {
      confirmDialog
    };
  },
  components: {
    VPagination,
    ConfirmDialog
  },
  props: {
    itemsPerPage: {
      type: Number,
      required: false,
      default: 10
    }
  },
  data() {
    return {
      currentPage: 1,
      searchText: "",
      loading: false,
      error: "" as any,
      emailAddress: "",
    };
  },
  async mounted() {
    this.error = "";
    this.loading = true;

    try {
      this.$store.dispatch(GET_FOLDERS, {
        paginationInformation: { currentPage: 1, itemsPerPage: 9999999 }
      });
      this.$store.dispatch(GET_RULES, this.listConfig);

      if (this.$route.params.ruleId) {
        this.getRule(this.$route.params.ruleId);
      } else if (typeof this.rule !== "undefined" && this.rule != null) {
        this.$store.dispatch(RESET_RULE);
      }

      this.error = "";
    } catch (e) {
      this.error = e;
    } finally {
      this.loading = false;
    }
  },
  computed: {
    ...mapState({
      folders: (state) => (state as State).folders.folders,
      rules: (state) => (state as State).rules.rules,
      rule: (state) => (state as State).rules.rule,
      rulesPagination: (state) => (state as State).rules.rulesPagination
    }),
    listConfig() {
      const filters = {
        paginationInformation: {
          currentPage: this.currentPage,
          itemsPerPage: this.itemsPerPage
        },
        selectedRuleId: null as any,
        type: 1
      };

      if (this.$route.params.ruleId) {
        filters.selectedRuleId = this.$route.params.ruleId;
      }

      return filters;
    }

  },
  watch: {
    currentPage() {
      this.getRules();
    }
  },
  methods: {
    async deleteBlockedSender(id) {
      const ok = await this.confirmDialog.show({
        title: "Delete",
        message: "Are you sure you want to delete this blocked sender?",
        okButton: "Delete"
      });
      if (ok) {
        this.$store.dispatch(DELETE_RULE, id).then(() => {
          this.$store.dispatch(RESET_RULE).then(() => {
            this.getRules();
          });
        });
      }
    },
    getFolders() {
      this.$store.dispatch(GET_FOLDERS, {
        paginationInformation: { currentPage: 1, itemsPerPage: 9999999 }
      });
    },
    async getRules() {
      await this.$store.dispatch(RESET_RULE);
      await this.$store.dispatch(GET_RULES, this.listConfig);
    },
    getRule(id, isToggle = false) {
      var rl = this.rule || { id: "" };

      if (isToggle && (id === "" || rl.id === id)) {
        this.$store.dispatch(RESET_RULE);
      } else {
        this.$store.dispatch(GET_RULE, { ruleId: id });
      }
    },
    resetPagination() {
      this.currentPage = 1;
    },
    async blockSender() {
      await this.$store.dispatch(BLOCK_SENDER, { emailAddress: this.emailAddress } as BlockedSenderModel)
      await this.getRules();
    },
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch(RESET_RULE);

    next();
  }
});
